<template>
    <div class="info">
        <div class="p1">232</div>
        <div class="p2">进阶币</div>
        <div class="div1">
            <div class="txt1">
                如何获取？
            </div>
            <div class="txt2">
                1、首次做题可得500进阶币 <br>
                2、进度每前进5%可得50进阶币 <br>
                3、每日做题数量超过一定数额可随机获得最高500进阶币 <br>
                4、完成全部进度可额外获得1000进阶币
            </div>
        </div>
        <div class="div1">
            <div class="txt1">
                有什么用？
            </div>
            <div class="txt2">
                100进阶币=1元 <br>
                很简单，每满3000币可直接问客服留言要红包，或者兑换答疑次数 <br>
                如果上岸后拿着成绩单来，还会有上岸贺礼哟~
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
    .info{
        min-height: 100vh;
        background: #F7F8FB;
        .p1{
            padding-top: 48px;
            color: #415FD5;
            font-family: 'DIN Alternate';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 47px;
            /* identical to box height */
            text-align: center;
            text-align: center;
        }
        .p2{
            padding-top: 10px;
            padding-bottom: 40px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #979797;
        }
        .div1{
            margin: 16px 22px;
            background: #FFFFFF;
            box-shadow: 0px 1px 8px rgba(42, 49, 85, 0.1);
            border-radius: 12px;
            text-align: left;
            padding: 16px 14px;
            .txt1{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #2A3155;
                position: relative;
                padding-left: 10px;
                &::before{
                    content: '';
                    position: absolute;
                    left: -1px;
                    top: 3px;
                    width: 4px;
                    height: 12px;
                    background: #415FD5;
                    border-radius: 4px;
                }
            }
            .txt2{
                padding-top: 10px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #9498AA;
            }
        }
    }
</style>